import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown/with-html"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  FormControl,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const AboutPageTemplate = ({
  title,
  content,
  wordCloud,
  bookSignUp,
  staffSection,
}) => {
  const [state, setState] = useState({})

  useEffect(() => {
    staffSection.people.forEach((person, i) => {
      setState(prevState => ({ ...prevState, [`person${i}`]: false }))
    })
  }, [])

  return (
    <Layout>
      <SEO title="About" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center about-landing-image">
            <div className="overlay"></div>
            <div style={{ height: "65vh" }}>
              <h1 className="sectionTitle">{title}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center about-section2">
        <Col xs={10} lg={8}>
          <p className="pt-5 mt-5">
            <ReactMarkdown
              source={content}
              escapeHtml={false}
              linkTarget="_blank"
            />
          </p>
        </Col>
        <Col className="about-words pb-5" xs={10} lg={8}>
          <p style={{ fontSize: "7vw" }}>{wordCloud.word1}</p>

          <p style={{ fontSize: "7vw", textAlign: "center" }}>
            {wordCloud.word2}
          </p>

          <p
            style={{
              fontSize: "6vw",
              textAlign: "right",
              lineHeight: "0.75em",
            }}
          >
            {wordCloud.word3}
          </p>
          <p
            style={{
              fontSize: "13vw",
              textAlign: "center",
              lineHeight: "0.75em",
            }}
          >
            {wordCloud.word4}
          </p>
          <p
            style={{
              fontSize: "3vw",
              textAlign: "left",
              lineHeight: "2em",
            }}
          >
            {wordCloud.word5}
          </p>
          <p
            style={{
              fontSize: "7vw",
              textAlign: "center",
              lineHeight: "1em",
            }}
          >
            {wordCloud.word6}
          </p>
          <p
            style={{
              fontSize: "9vw",
              textAlign: "left",
              lineHeight: "1em",
            }}
          >
            {wordCloud.word7}
          </p>
          <p
            style={{
              fontSize: "16vw",
              textAlign: "right",
              lineHeight: "0em",
            }}
          >
            {wordCloud.word8}
          </p>
          <p
            style={{
              fontSize: "3vw",
              textAlign: "left",
              marginLeft: "5vw",
              lineHeight: ".5em",
            }}
          >
            {wordCloud.word9}
          </p>
          <p
            style={{
              fontSize: "8vw",
              textAlign: "left",
              lineHeight: "1em",
            }}
          >
            {wordCloud.word10}
          </p>
          <p
            style={{
              fontSize: "3vw",
              textAlign: "right",
              lineHeight: "0.5em",
            }}
          >
            {wordCloud.word11}
          </p>
          <p
            style={{
              fontSize: "4vw",
              textAlign: "left",
              marginLeft: "5vw",
              lineHeight: "0.5em",
            }}
          >
            {wordCloud.word12}
          </p>
          <p
            style={{
              fontSize: "6vw",
              textAlign: "left",
              marginLeft: "10vw",
              lineHeight: "0.75em",
            }}
          >
            {wordCloud.word13}
          </p>
          <p
            style={{
              fontSize: "3vw",
              textAlign: "right",
              marginLeft: "0",
              lineHeight: "1em",
            }}
          >
            {wordCloud.word14}
          </p>
          <p
            style={{
              fontSize: "14vw",
              textAlign: "center",
              marginLeft: "0",
              lineHeight: "0.75em",
            }}
          >
            {wordCloud.word15}
          </p>
          <p
            style={{
              fontSize: "4vw",
              textAlign: "right",
              marginRight: "3vw",
              lineHeight: "1em",
            }}
          >
            {wordCloud.word16}
          </p>
          <p
            style={{
              fontSize: "5vw",
              textAlign: "left",
              marginLeft: "0",
              lineHeight: "0.5em",
            }}
          >
            {wordCloud.word17}
          </p>
          <p
            style={{
              fontSize: "12vw",
              textAlign: "center",
              marginLeft: "0",
              lineHeight: "0.75em",
            }}
          >
            {wordCloud.word18}
          </p>
        </Col>
      </Row>

      <Row className="about-section-curious d-flex flex-row justify-content-center align-items-center">
        <Col xs={9} lg={8}>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="sectionTitleWrapper">
              <h1 className="mb-3">{bookSignUp.title}</h1>
              <p>
                <ReactMarkdown
                  source={bookSignUp.content}
                  escapeHtml={false}
                  linkTarget="_blank"
                />
              </p>
            </div>
          </div>
          <Form
            className="mt-5"
            action="https://t91884b07.emailsys1a.net/122/3601/fac4ff1e79/subscribe/form.html"
            method="post"
            class="validate"
            target="_blank"
            novalidate
          >
            <Row>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="First name"
                  name="first_name"
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="Last name"
                  name="last_name"
                />
                <Form.Control
                  size="lg"
                  placeholder="book"
                  name="TAGS"
                  value="BOOK"
                  style={{ display: "none" }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputGroup className="mt-3">
                  <FormControl
                    size="lg"
                    placeholder="enter your email address"
                    aria-label="enter your email address"
                    aria-describedby="email address"
                    name="email"
                  />
                  <FormControl
                    name="rm_email"
                    id="rm_email"
                    type="text"
                    value=""
                    tabindex="-1"
                    style={{
                      position: "absolute",
                      zIndex: "-100",
                      left: "-6000px",
                    }}
                  ></FormControl>
                  <InputGroup.Append>
                    <Button type="submit">SIGN UP</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={8} className="align-self-center"></Col>
      </Row>

      <Row className="about-section-team" id="team">
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay-top"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">{staffSection.title}</h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>

      <Row className="about-team d-flex flex-column justify-content-center align-items-center">
        <Col xs={10} md={11} xl={8}>
          {staffSection.people.map((person, i) => {
            return (
              <Row
                // eslint-disable-next-line
                className={i % 2 === 0 ? "flex-row" : "flex-row-reverse"}
                style={{ marginBottom: "10%" }}
              >
                <Col
                  xs={10}
                  sm={{ span: 6, offset: 3 }}
                  lg={{ span: 4, offset: 0 }}
                  order={1}
                >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>View LinkedIn</Tooltip>
                    }
                  >
                    <a href={person.profileURL}>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: person.profilePicture,
                          alt: "Lightschools image",
                          className: "image",
                        }}
                      />
                    </a>
                  </OverlayTrigger>
                </Col>
                <Col xs={12} sm={12} lg={8}>
                  <h1>{person.name}</h1>

                  <p>
                    <ReactMarkdown
                      source={person.body}
                      escapeHtml={false}
                      linkTarget="_blank"
                    />
                    <span
                      style={
                        state[`person${i}`]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ReactMarkdown
                        source={person.extendedBody}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                    </span>
                  </p>
                  <div className="carot">
                    <Button
                      variant="link"
                      onClick={() =>
                        setState(prevState => ({
                          ...prevState,
                          [`person${i}`]: !state[`person${i}`],
                        }))
                      }
                    >
                      {!state[`person${i}`] ? (
                        <svg
                          class="bi bi-caret-down-fill"
                          width="2em"
                          height="2em"
                          viewBox="0 0 16 16"
                          style={{ marginLeft: "8px" }}
                          fill="black"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                        </svg>
                      ) : (
                        <svg
                          class="bi bi-caret-up-fill"
                          width="2em"
                          height="2em"
                          viewBox="0 0 16 16"
                          fill="black"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 00.753-1.659l-4.796-5.48a1 1 0 00-1.506 0z" />
                        </svg>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            )
          })}
          {/* <Row style={{ marginBottom: "10%" }}>
            <Col xs={{ span: 12, offset: 0, order: 1 }} lg={8}>
              <h1>WILLY WIJNANDS </h1>
              <p>
                Willy Wijnands is initial co-creator of Lightschools, founder of
                eduScrum and a passionate Chemistry and Science teacher since
                1976. He is a true connector and therefore also co-founder of
                the global initiative{" "}
                <a href="http://www.agileineducation.org/">
                  Agile in Education
                </a>
                . “It is not about doing agile, but about being agile”, as Willy
                says. He uses eduScrum and the agile mindset with students and
                trainers worldwide in all his classes and projects, no matter
                the subject.{" "}
                <span
                  style={show2 ? { display: "block" } : { display: "none" }}
                >
                  An important basis for his thinking and acting has been
                  created in his years of involvement in Aikido, Chi Kung and
                  Shiatsu. He wrote and contributed to several books on agility
                  and eduScrum (
                  <a href="http://eduscrum.nl/en/file/CKFiles/The_eduScrum_Guide_EN_1.2(1).pdf">
                    the eduScrum guide
                  </a>
                  ,{" "}
                  <a href="https://books.google.nl/books?id=DM50DwAAQBAJ&pg=PA131&lpg=PA131&dq=google+books+agile+and+lean+concepts+for+teaching+and+learning:+bringing+methodologies+from+industry+to+the+classroom+eduscrum&source=bl&ots=h6nn1em9aB&sig=JXQWjJ8OgaRlJRqlJuytBfREplQ&hl=nl&sa=X&ved=2ahUKEwiQg8Ox6JzfAhWBy6QKHUqzCygQ6AEwA3oECAUQAQ">
                    Agile and Lean Concepts for Teaching and Learning
                  </a>{" "}
                  and{" "}
                  <a href="https://books.google.nl/books/about/Scrum_in_actie.html?id=sS_MCQAAQBAJ&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false">
                    Scrum in Actie
                  </a>
                  ). Within Lightschools Willy wants to build a bridge between
                  the educational system and market requirements. He believes
                  that we can empower each other by collaborating. Together we
                  create projects and products that contribute to a bright new
                  world and develop students into lifelong learners and leaders
                  of tomorrow.
                </span>
              </p>
              <div className="carot">
                <Button variant="link" onClick={() => toggleShow2(!show2)}>
                  {!show2 ? (
                    <svg
                      class="bi bi-caret-down-fill"
                      width="2em"
                      height="2em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  ) : (
                    <svg
                      class="bi bi-caret-up-fill"
                      width="2em"
                      height="2em"
                      viewBox="0 0 16 16"
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 00.753-1.659l-4.796-5.48a1 1 0 00-1.506 0z" />
                    </svg>
                  )}
                </Button>
              </div>
            </Col>
            <Col
              xs={{ span: 10, offset: 0, order: -1 }}
              sm={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 0, order: 2 }}
            >
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>View LinkedIn</Tooltip>}
              >
                <a href="https://www.linkedin.com/in/willy-wijnands-41077254/">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: image2,
                      alt: "Lightschools image",
                      className: "image",
                    }}
                  />
                </a>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5%" }}>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 0 }}
            >
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>View LinkedIn</Tooltip>}
              >
                <a href="https://www.linkedin.com/in/kristina-fritsch-783302188/">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: image3,
                      alt: "Lightschools image",
                      className: "image",
                    }}
                  />
                </a>
              </OverlayTrigger>
            </Col>
            <Col xs={{ span: 12, offset: 0, order: 1 }} lg={8}>
              <h1>KRISTINA FRITSCH{"  "}</h1>

              <p>
                Kristina Fritsch is the initial co-creator of Lightschools and
                works in various educational, cultural, and art projects with
                learners of all ages. She implements and lives agile learning
                concepts. As part of the eduScrum team, she trains teachers and
                lecturers from primary school to vocational training as well as
                eduScrum trainers in close cooperation with Willy Wijnands. She
                is highly interested in gaining and giving impulses to create
                spheres of inspiring human encounters and shared learning.
                <span
                  style={show3 ? { display: "block" } : { display: "none" }}
                >
                  As a certified Scrum Master and Product Owner, trained by the
                  Scrum founder Jeff Sutherland, her focus is mainly on the
                  introduction of agile methods in small and medium-sized
                  companies. Kristina wants to encourage and empower people to
                  flourish in and for a peaceful and humanistic future. As an
                  educator, resilience trainer, and mother of two children,
                  Kristina loves to support enthusiastic teachers, students, and
                  people dedicated to education all around the world.
                </span>
              </p>
              <div className="carot">
                <Button variant="link" onClick={() => toggleShow3(!show3)}>
                  {!show3 ? (
                    <svg
                      class="bi bi-caret-down-fill"
                      width="2em"
                      height="2em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  ) : (
                    <svg
                      class="bi bi-caret-up-fill"
                      width="2em"
                      height="2em"
                      viewBox="0 0 16 16"
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 00.753-1.659l-4.796-5.48a1 1 0 00-1.506 0z" />
                    </svg>
                  )}
                </Button>
              </div>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Layout>
  )
}

const AboutPage = props => {
  const { frontmatter } = props.data.AboutPageTemplate
  return (
    <AboutPageTemplate
      title={frontmatter.title}
      content={frontmatter.content}
      wordCloud={frontmatter.wordCloud}
      bookSignUp={frontmatter.bookSignUp}
      staffSection={frontmatter.staffSection}
    />
  )
}

export default AboutPage

export const pageQuery = graphql`
  query($id: String!) {
    AboutPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        wordCloud {
          word1
          word2
          word3
          word10
          word4
          word11
          word5
          word12
          word6
          word13
          word7
          word14
          word8
          word15
          word9
          word16
          word17
          word18
        }
        bookSignUp {
          title
          content
        }
        staffSection {
          title
          people {
            name
            profilePicture
            body
            extendedBody
            profileURL
          }
        }
      }
    }
  }
`
